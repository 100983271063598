import {Alert, Box, LinearProgress, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import {useEffect, useState} from "react";
import {socket} from "../service/socket";
import toast from "react-hot-toast";

function MovieDialogContent({ handleClose }) {
  const [movieLink, setMovieLink] = useState('');
  const [percentage, setPercentage] = useState(0);
  const [finishedDownload, setFinishedDownload] = useState(false);
  
  const handleLinkChange = (event) => {
    setMovieLink(event.target.value);
  }
  const handleDownload = async () => {
    socket.emit('sendMovie', { link: movieLink });
  }
  
  useEffect(() => {
    socket.on('receivePercentage', (args) => {
      setPercentage(args);
    })
    if (percentage === 100) {
      setPercentage(0);
      setFinishedDownload(true);
      toast.success('Le film a été téléchargé avec succès !')
      handleClose();
    }
  }, [handleClose, percentage])
  
  const styles = {
    dlButton: {
      textAlign: 'end',
      marginTop: '1rem',
    }
  }
  return (
    <Box>
      <TextField onChange={handleLinkChange} sx={{marginTop: '0.5rem'}} id="outlined-basic" label="Lien du film" fullWidth required />
      {percentage === 0 && (
        <Button onClick={handleDownload} sx={styles.dlButton} variant="outlined">Télécharger le film</Button>
      )}
      {percentage > 0 && (
        <LinearProgress sx={{marginTop: '0.5rem', height: '1rem'}} variant="determinate" value={percentage} />
      )}
    </Box>
  );
}


export default MovieDialogContent;