import {Box, Card, CardContent, TextField, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import { useState } from "react";
import JellyfinLogo from '../assets/images/jellyfinLogo.png';
import {useNavigate} from "react-router-dom";
import axios from "axios";
import toast from 'react-hot-toast';

function Login() {
  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  
  const navigate = useNavigate();

  const styles = {
    box: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    },
    logo: {
      width: '100px',
    },
    card: {
      borderRadius: '10px',
      height: 'fit-content',
      width: '20%',
    },
    boxInput: {
      width: '100%',
      marginTop: '1rem',
      textAlign: 'center',
    }
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = () => {
    axios.post(process.env.REACT_APP_BACK_URL + '/auth/login', {email, password}).then(res  => {
      if (res.data.jwt) {
        localStorage.setItem('jwt', res.data.jwt);
        toast.success('Connexion réussie');
        navigate('/dashboard');
      }
    }).catch(err => {
      toast.error(err.response.data.message);
    })
  };


  return (
    <Box sx={styles.box}>
      <Card sx={styles.card}>
        <CardContent>
          <Box sx={styles.boxInput}>
            <img style={styles.logo} src={JellyfinLogo} alt="Logo Jellyfin"/>
          </Box>
          <Box sx={styles.boxInput}>
            <Typography>Jellyfin Downloader v0.1.2</Typography>
          </Box>
          <Box sx={styles.boxInput}>
            <TextField onChange={handleEmailChange} id="outlined-basic" label="Adresse email" variant="outlined" fullWidth />
          </Box>
          <Box sx={styles.boxInput}>
            <TextField onChange={handlePasswordChange} id="outlined-password" label="Mot de passe" type="password" variant="outlined" fullWidth />
          </Box>
          <Box sx={styles.boxInput}>
            <Button onClick={handleLogin} variant="contained">Se connecter</Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )

}

export default Login;