import {Box, ButtonGroup, Container, Typography} from "@mui/material";
import AddMediaDialog from "../components/AddMediaDialog";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import toast from "react-hot-toast";

function Dashboard() {
  const navigate = useNavigate();
  
  const handleDisconnect = () => {
    localStorage.removeItem('jwt');
    toast.success('Déconnexion réussie');
    navigate('/login');
  }
  
  const styles = {
    boxStyle: {
      display: 'flex',
      justifyContent: 'space-between', // Aligne les éléments à l'extrémité droite
      alignItems: 'center'
    },
    boxImg: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '8rem',
      opacity: '0.6',
    },
    boxEmptyState: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '1rem',
    },
    infoBox: {
      backgroundColor: 'white',
      border: '1px solid #B0B0B0',
      marginTop: '1rem',
      padding: '1rem',
      borderRadius: '0.5rem',
      boxShadow: '3px 3px 5px black',
    }
  }
  return (
    <Container>
      <Box sx={styles.boxStyle}>
        <Box>
          <h1>Dashboard</h1>
        </Box>
        <Box>
          <Button variant="contained" onClick={handleDisconnect} color="error">Déconnexion</Button>
        </Box>
      </Box>
      
      <Box>
        <ButtonGroup variant="contained" aria-label="outlined primary button group">
          <AddMediaDialog title={'Ajouter un film'} type={'movie'} />
          <AddMediaDialog title={'Ajouter une série'} type={'tvShow'} />
        </ButtonGroup>
      </Box>

      <Box sx={styles.infoBox}>
        <Typography variant="h4">Liste des sites de téléchargement</Typography>
        <ul>
          <li><a href="https://zone-telechargement.kim/" target="_blank" rel="noreferrer">Zone-Téléchargement</a></li>
        </ul>
      </Box>
    </Container>
  );
}

export default Dashboard;