import {useEffect, useState} from "react";
import axios from "axios";
import toast from "react-hot-toast";
import {Box, FormControl, InputLabel, LinearProgress, MenuItem, Select, TextField, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {socket} from "../service/socket";

function TvShowDialogContent({ handleClose }) {
  const [tvShows, setTvShows] = useState([])
  const [link, setLink] = useState('');
  const [selectedTvShow, setSelectedTvShow] = useState('');
  const [notExists, setNotExists] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [finishedDownload, setFinishedDownload] = useState(false);
  
  const handleLink = (event) => {
    setLink(event.target.value);
  }
  
  const handleSelectChange = (event) => {
    setSelectedTvShow(event.target.value);
  }
  
  const handleClickTvShowNotExist = () => {
    setNotExists(true);
  }
  
  const handleDownload = () => {
    socket.emit('sendTvShow', { link, tvShow: selectedTvShow });
  }

  socket.on('receivePercentageTvShow', (args) => {
    setPercentage(args);
  })
  if (percentage === 100) {
    setPercentage(0);
    setFinishedDownload(true);
    toast.success('L\'épisode a été téléchargé avec succès !')
    handleClose();
  }
  
  useEffect(() => {
    axios.get(process.env.REACT_APP_BACK_URL+'/file/').then(res => {
      setTvShows(res.data);
    }).catch(err => {
      toast.error(err);
    })
  }, [setTvShows])
  
  const styles = {
    dlButton: {
      textAlign: 'end',
      marginTop: '1rem',
    }
  }
  
  return (
    <Box>
      <Box>
        <Typography>Sélectionner la série</Typography>
      </Box>
      {!notExists && (
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Séries</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            value={selectedTvShow}
            id="demo-simple-select"
            label="Séries"
            onChange={handleSelectChange}
          >
            {tvShows.map((tvShow, index) => (
              <MenuItem key={index} value={tvShow}>{tvShow}</MenuItem>
            ))}
          </Select>
          <Button sx={{marginTop: '0.5rem'}} variant={"outlined"} onClick={handleClickTvShowNotExist} fullWidth>Cliquez ici si la série n'existe pas</Button>
        </FormControl>
      )}
      {notExists && (
        <TextField onChange={handleSelectChange} sx={{marginTop: '0.5rem'}} id="outlined-basic" label="Nom de la série" variant="outlined" fullWidth />
      )}
      <TextField onChange={handleLink} sx={{marginTop: '0.5rem'}} id="outlined-basic" label="Lien de l'épisode" variant="outlined" fullWidth required />
      {percentage === 0 && (
        <Button onClick={handleDownload} sx={styles.dlButton} variant="outlined">Télécharger l'épisode</Button>
      )}
      {percentage > 0 && (
          <LinearProgress sx={{marginTop: '0.5rem', height: '1rem'}} variant="determinate" value={percentage} />
      )}
    </Box>
  )
}

export default TvShowDialogContent;