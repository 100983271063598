import {Box, Dialog, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import {useState} from "react";
import * as React from "react";
import MovieDialogContent from "./MovieDialogContent";
import TvShowDialogContent from "./TvShowDialogContent";

function AddMediaDialog({ title, type }) {
  
  const [open, setOpen] = useState(false);
  
  const handleClickOpen = () => {
    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
  };
  
  const styles = {
    dialog: {
      width: '50%'
    }
  }
  
  return (
    <Box>
      <Button onClick={handleClickOpen}>{title}</Button>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        {type === 'movie' ? (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <MovieDialogContent handleClose={handleClose} />
            </DialogContentText>
          </DialogContent>
        ) : (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <TvShowDialogContent handleClose={handleClose} />
            </DialogContentText>
          </DialogContent>
        )}
        
        {/*<DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions>*/}
      </Dialog>
    </Box>
  )
}

export default AddMediaDialog;