import {Navigate} from "react-router-dom";

function PrivateRoute({ children }) {
  const isLoggedIn = localStorage.getItem('jwt');
  if (!isLoggedIn) {
    return (<Navigate to="/login" />)
  }
  return children;
}

export default PrivateRoute;